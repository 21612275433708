import logo from './logo.svg';
import './App.css';
import Hero from './Hero';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './Footer';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
function App() {
  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <div className="App">
     <Hero/>
     <Footer/>
    </div>
    </ThemeProvider>
  );
}

export default App;
