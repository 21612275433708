

import {Box ,Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useState } from 'react';

function Hero () {

    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneNumberChange = (newVal) => {
        setPhoneNumber(newVal);
    }

    return (
        <Box sx={{height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <Typography variant='h3'>
            Hi, we are <span className='logo'>Violet</span>
            </Typography>
            <Typography variant='h4' sx={{letterSpacing:'0.110em'}}>Nice to meet you!</Typography>
            <Box id='homepage-form' sx={{mt:2,width:'330px'}}>
                <Typography className='form-label'  variant='body2' >your phone number</Typography>
                <MuiTelInput sx={{ width:'100%'}} value={phoneNumber} onChange={handlePhoneNumberChange} />
                <Typography className='form-label'  variant='body2' >your name</Typography>
                <TextField sx={{ width:'100%'}} id="outlined-basic" label="" variant="outlined" />
                <Button color="secondary" size='large' sx={{mt:2, width:'100%', textTransform:'capitalize'}} variant="contained">join our beta</Button>
            </Box>
        </Box>
    )
}

export default Hero